import { all } from "redux-saga/effects";
import { appSaga } from "../modules/app";
import { userSaga } from "../modules/user";
import {homeSaga} from '../modules/home'
import {profileSaga} from '../modules/profile'
const sagas = [
  appSaga(),
  userSaga(),
  homeSaga(),
  profileSaga(),
];

export default function*() {
  yield all(sagas);
}
