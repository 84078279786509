import { createStore, applyMiddleware, combineReducers } from "redux";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import thunk from "redux-thunk";
import { routerMiddleware } from "react-router-redux";
import reducers from "./reducers";
import sagas from "./sagas";
import { persistStore, persistReducer,} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
export const APP_INIT = "APP_INIT";

const persistConfig = {
  key: '@local10010',
  storage,
}

const sagaMiddleware = createSagaMiddleware();
const middleware = routerMiddleware();

const composedEnhancers = composeWithDevTools(
  applyMiddleware(...[sagaMiddleware], middleware, thunk)
);
const reducer = combineReducers(reducers);

const rootReducer = (state = {}, action) => {
  let newState;
  newState = state;
  return reducer(newState, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer)
export const store = createStore(persistedReducer, composedEnhancers);
export const persistor = persistStore(store)
sagaMiddleware.run(sagas);

export default store;
