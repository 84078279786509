import axios from 'axios';
const baseURL = process.env.REACT_APP_API_URL;

export const startStream = async ({tran_id,ref,type}) => {
   try {
     await axios.post(`${baseURL}payment/start`, { 
      code:tran_id,
      ref,
      type
      });
   } catch (error) {
     console.error('Error starting stream:', error);
   }
 };

export const progressStream = async ({tran_id,ref,type},callback) => {
  try {
    // eslint-disable-next-line no-useless-concat
    const response = await axios.get(`${baseURL}payment/progress?code=${tran_id}&ref=${ref}&type=${type}`,{timeout: 3 * 60 * 1000 });
    callback({
      ...response
    })
    } catch (error) {
    console.error('Error starting stream:', error);
  }
};

export const validStream=async(tran_id,callback)=>{
  try {
    const response = await axios.get(`${baseURL}payment/valid?code=${tran_id}`);
    callback({
      ...response
    })
    } catch (error) {
      console.error('Error exist code:', error);
    }
}

export const pushSuccess = async (tran_id) => {
  try {
    // eslint-disable-next-line no-useless-concat
    console.log('push success',tran_id)
    const response = await axios.post(`${baseURL}push-back-transaction`,{
      "tran_id":tran_id,
      "apv":tran_id,
      "status":0
  });
    return response;
    } catch (error) {
    console.error('Error push back:', error);
  }
};
