import { routerReducer } from "react-router-redux";
import appReducer from "../modules/app/reducer";
import userReducer from "../modules/user/reducer";
import homeReducer from '../modules/home/reducer'
import profileReducer from '../modules/profile/reducer'
export default {
  app: appReducer,
  user: userReducer,
  router: routerReducer,
  home:homeReducer,
  profile:profileReducer,
};
