import React from 'react';
import { IMAGE } from '../assets/image';
import "../styles/components/headers.css"
import {Col,Row} from 'react-bootstrap'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import {Button} from '@material-ui/core';
import {ArrowBackIos} from '@material-ui/icons';
export default class Header extends React.Component{
  render() {
    return (
            <div className="container" >
            <Row style={{padding:0,margin:0}} className="control_header">
                <Col className="text-center " id="col___l" xl={3} lg={3} md={3} xs={5} sm={4} style={{padding:0,margin:0}}>
                    {
                        this.props.signup ?
                        this.props.signup
                        :
                        typeof this.props.back!== 'undefined' ?
                        <div>
                          <Button id="button_back_nav" onClick={()=>this.props.back()} startIcon={
                            <ArrowBackIos/>
                          }>ទំព័រដើម</Button>
                        </div>
                        :
                        <div className="div_logo_resuldHeader" >
                          {
                            this.props?.seasonal?
                            <img alt='logo' src={IMAGE.logo} id="logo_image_result_header_seasonal" /> :
                            <img alt='logo' src={IMAGE.logotwo} id="logo_image_result_header" />
                          }
                        </div>
                    }
                </Col>
                <Col className="text-center" xl={6} lg={6} md={6} xs={2} sm={4} style={{padding:0,margin:0}}>
                    {
                        this.props.titleCenter ?
                        this.props.titleCenter
                        :null
                    }
                </Col>
                <Col className="text-center " id="col___f"  xl={3} lg={3} md={3} xs={5} sm={4} style={{padding:0,margin:0}}>
                    {
                      this.props.logout?
                      <button 
                        onClick={this.props.logout}
                        type="button" 
                        className=" _buttonNotification"
                        data-toggle="tooltip" 
                        data-placement="top" 
                        title="ចាកចេញ">
                            <PowerSettingsNewIcon id="btnLogout_icon"/> 
                      </button>
                      :null
                    }
                </Col>
            </Row>
            </div>
         );
  }
}
