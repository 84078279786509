import { success, error, abort } from "redux-saga-requests";

export const PROFILE='PROFILE';
export const PROFILE_SEASONAL='PROFILE_SEASONAL';

export const profile_f = (payload) =>({
    type: PROFILE,
    payload
});

export const profile_seasonal_f = (payload) =>({
    type: PROFILE_SEASONAL,
    payload
});
const initialState = {
    pending: false,
    error: false,
};

const profileReducer = (state = initialState, action) => {
    switch (action.type) {
    
        //PROFILE
        case PROFILE:{
            return{...state,pending:true};
            }
            case success(PROFILE):{
                return{
                    ...state,
                    ...action.payload,
                    pending:false};
            }
            case error(PROFILE):{
                return{...state,
                    error:true,
                    pending:false,
                    ...action.payload
                };
            }
            case abort(PROFILE):{
                return{...state,pending:false};
            }

        //PROFILE_SEASONAL
        case PROFILE_SEASONAL:{
            return{...state,pending:true};
            }
            case success(PROFILE_SEASONAL):{
                return{
                    ...state,
                    ...action.payload,
                    pending:false};
            }
            case error(PROFILE_SEASONAL):{
                return{...state,
                    error:true,
                    pending:false,
                    ...action.payload
                };
            }
            case abort(PROFILE_SEASONAL):{
                return{...state,pending:false};
            }

    /**
     * DEFAULT_CASE
     */
    default:
        return state;
    }
};
export default profileReducer;