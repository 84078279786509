import { connect } from "react-redux";
import ScreenNotification from "../screen/ScreenNotification";
const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = {

}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScreenNotification);
