import { call, put,takeLatest} from "redux-saga/effects";
import { error, success } from "redux-saga-requests";
import {axios} from "../app/index";
import {normalize} from "../../utils/normalize";

import {
    LOGIN,
    SIGNUP,
} from "./reducer";

export function* userLoginWorker({ payload }) {
    try { 
        const login = yield call(axios.post,"/login",payload);
        const requested = normalize(login);
        if(requested.token){
            yield (axios.defaults.headers.common = {
                 "Authorization": `Bearer ${requested.token}`
             });
         }
        yield put({
            type: success(LOGIN),
            payload: {
                login: requested,
            }
        });
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e.response));
        const message = parseError.data;
        yield put({
            type: error(LOGIN),
            payload: {
                errorLogin:message
            }
        });
    }
}
export function* userSignUpWorker({ payload }) {
    try { 
        const signup = yield call(axios.post,"/sign-up",payload);
        const requested = normalize(signup);
         if(requested && requested?.token){
            yield (axios.defaults.headers.common = {
                 "Authorization": `Bearer ${requested.token}`
             });
         }
         yield put({
            type: success(SIGNUP),
            payload: {
                signup: requested,
            }
        });
        
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e.response));
        const message = parseError.data;
        yield put({
            type: error(SIGNUP),
            payload: {
                errorSignup:message
            }
        });
    }
}


export function* userSaga() {
    yield takeLatest(LOGIN, userLoginWorker);
    yield takeLatest(SIGNUP, userSignUpWorker);
}

  