import React, { Component } from 'react';
import 'react-activity/dist/react-activity.css';
import { Windmill} from 'react-activity';
import "../styles/components/loading.css"
export default class Loading extends Component {
    render() {
        return(
            <div className="loadingNig">
                <div style={{display:'flex',textAlign:'center',alignItems:'center',flexDirection:'column'}}>
                    <div style={{marginBottom:20}}>
                        <Windmill 
                            color="grey" 
                            size={20} 
                            speed={1} 
                            animating={true} 
                            />
                    </div>
                    <h5 className="h5_loading">{this.props?.title?this.props?.title:"សូមរង់ចាំ..."}</h5>
                </div>
                
            </div>
            
           );
    }
}
