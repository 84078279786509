export const GENDER=[{title:'ស្រី', value:'Female'},{title:'ប្រុស', value:'Male'}];
export const MONTH=["មករា", "កុម្ភៈ", "មីនា", "មេសា", "ឧសភា", "មិថុនា", "កក្កដា", "សីហា", "កញ្ញា", "តុលា", "វិច្ឆិកា", "ធ្នូ"];
export const CITY=[
{ title:'កណ្តាល',value:'កណ្តាល'},
{ title:'កែប',value:'កែប'},
{ title:'កោះកុង',value:'កោះកុង'},
{ title:'ក្រចេះ',value:'ក្រចេះ'},
{ title:'កំពង់ចាម',value:'កំពង់ចាម'},
{ title:'កំពង់ឆ្នាំង',value:'កំពង់ឆ្នាំង'},
{ title:'កំពង់ធំ',value:'កំពង់ធំ'},
{ title:'កំពង់ស្ពឺ',value:'កំពង់ស្ពឺ'},
{ title:'កំពត',value:'កំពត'},
{ title:'តាកែវ',value:'តាកែវ'},
{ title:'ត្បូងឃ្មុំ',value:'ត្បូងឃ្មុំ'},
{ title:'បន្ទាយមានជ័យ',value:'បន្ទាយមានជ័យ'},
{ title:'ប៉ៃលិន',value:'ប៉ៃលិន'},
{ title:'បាត់ដំបង',value:'បាត់ដំបង'},
{ title:'ពោធិ៍សាត់',value:'ពោធិ៍សាត់'},
{ title:'ព្រៃវែង',value:'ព្រៃវែង'},
{ title:'ព្រះវិហារ',value:'ព្រះវិហារ'},
{ title:'ព្រះសីហនុ ',value:'ព្រះសីហនុ '},
{ title:'ភ្នំពេញ',value:'ភ្នំពេញ'},
{ title:'មណ្ឌលគិរី',value:'មណ្ឌលគិរី'},
{ title:'រតនៈគិរី',value:'រតនៈគិរី'},
{ title:'សៀមរាប',value:'សៀមរាប'},
{ title:'ស្ទឹងត្រែង',value:'ស្ទឹងត្រែង'},
{ title:'ស្វាយរៀង',value:'ស្វាយរៀង'},
{ title:'ឧត្ដរមានជ័យ',value:'ឧត្ដរមានជ័យ'},
]