import React from 'react';
import '../styles/ScreenNotification.css'
import { Col, Row } from 'react-bootstrap';
import OneNotification from '../components/oneNotification';
import Header from '../components/header';

export default class ScreenNotification extends React.Component {
  constructor() {
    super();
    this.state = {

    }
  }
  _lopNoti() {
    var data = [1, 2, 3, 4, 5, 6, 8, 9, 10, 11, 12, 13, 14, 15, 16];
    var rs = [];
    data.map(() => rs = [...rs, <OneNotification />])
    return rs;
  }
  render() {
    return (
      <div >
        <Row className="fixed_nav" >
          <Header notication={true}/>
        </Row>
        <Row className="_not_">
          <Col xl={3} lg={3} md={3} sm={3} xs={3} >

          </Col>
          <Col xl={6} lg={6} md={6} sm={6} xs={6} >
            {this._lopNoti()}
          </Col>
          <Col xl={3} lg={3} md={3} sm={3} xs={3}>

          </Col>
        </Row>
      </div>
    );
  }
}
