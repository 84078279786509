import { call, put,takeLatest} from "redux-saga/effects";
import { error, success } from "redux-saga-requests";
import {axios} from "../app/index";
import {normalize} from "../../utils/normalize";
import {pushBackTelegram} from '../../utils'
import {
    GET_JOB_TYPE,
    GET_JOB_TYPE_SEASONAL,
    VERIFY_MEMBER,
    VERIFY_PAY,
    SUBMIT_FORM_TEMPOTERY,
    WING_GENERATE_HASH,
    VERIFY_MEMBER_SEASONAL,
    SUBMIT_FORM_SEASONAL,
    ACLEDA_PAYMENT
} from "./reducer";

const HASH_KEY=process.env.REACT_APP_HASH_KEY;

export function* getTypeJobWorker({payload}) {
    try { 
        const type_job = yield call(axios.get,"/section-job/"+payload);
        const requested = normalize(type_job);
   
        yield put({
            type: success(GET_JOB_TYPE),
            payload: {
                type_job: requested,
                errorTypejob:false
            }
        });
        
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e.response));
        const message = parseError.data;
        yield put({
            type: error(GET_JOB_TYPE),
            payload: {
                errorTypejob:message
            }
        });
    }
}
export function* getTypeJobSeasonalWorker({payload}) {
    try { 
        const type_job = yield call(axios.get,"/section-job-seasonal/"+payload);
        const requested = normalize(type_job);
   
        yield put({
            type: success(GET_JOB_TYPE_SEASONAL),
            payload: {
                type_job_seasonal: requested,
                errorTypejobSeasonal:false
            }
        });
        
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e.response));
        const message = parseError.data;
        yield put({
            type: error(GET_JOB_TYPE_SEASONAL),
            payload: {
                errorTypejobSeasonal:message
            }
        });
    }
}



export function* verifyMemberWorker({payload}){
    try {
        const verify_member = yield call(axios.post,"/verify-member",payload);
        yield put({
            type: success(VERIFY_MEMBER),
            payload:{
                verify_member:normalize(verify_member),
                verify_member_error:false
            }
        });
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e?.response));
        const message = parseError?.data;
        yield put({
                type:error(VERIFY_MEMBER),
                payload:{verify_member_error:message,error:message}
            })
    }
}
export function* paymentVerifyWorker({payload}){
    try {
        const paymentVerify = yield call(axios.post,"/payment/verify",payload);
        yield put({
            type: success(VERIFY_PAY),
            payload:{
                paymentVerify:normalize(paymentVerify)
            }
        })
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError.data || parseError.response;

        yield put({
            type:error(VERIFY_PAY),
            payload:{error:message}
        })
    }
}
export function* SubmitFormTempoteryWorker({payload}){
    try {
        const form= new FormData();
        for (const key in payload) {
            const element = payload[key];
            form.append(key,element)
        }
        const dataUpload_tempotery = yield call(axios.post,"/submit-form",form);
        const requested = normalize(dataUpload_tempotery);
       
        yield put({
            type: success(SUBMIT_FORM_TEMPOTERY),
            payload:{
                dataUpload_tempotery:requested,
            }
        });
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e?.response));
        const message = parseError?.data;
        yield put({
            type:error(SUBMIT_FORM_TEMPOTERY),
            payload:{dataUpload_tempotery:message}
        });
                   
  }
}  

export function* SubmitFormSeasonalWorker({payload}){
    try {
        const form= new FormData();
        for (const key in payload) {
            const element = payload[key];
            form.append(key,element)
        }
        const dataUpload_seasonal = yield call(axios.post,"/submit-form-seasonal",form);
        const requested = normalize(dataUpload_seasonal);
       
        yield put({
            type: success(SUBMIT_FORM_SEASONAL),
            payload:{
                dataUpload_seasonal:requested,
            }
        });
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e?.response));
        const message = parseError?.data;
        yield put({
            type:error(SUBMIT_FORM_SEASONAL),
            payload:{dataUpload_seasonal:message}
        });
                   
  }
}  

export function* wingGenerateHashWorker({ payload }) {
try {
    const wingSKDToken = yield call(axios.post,"/wing-pay",payload);
    yield put({
        type: success(WING_GENERATE_HASH),
        payload:{
            wingHash:normalize(wingSKDToken)?.data,
        }
    });
} catch (e) {
    yield put({
    type: error(WING_GENERATE_HASH),
    payload: {
        wingHash: 'error',
    },
    });
}
} 
export function* verifyMemberSeasonalWorker({payload}){
    try {
        const verify_member_seasonal = yield call(axios.post,"/verify-member-seasonal",payload);
        yield put({
            type: success(VERIFY_MEMBER_SEASONAL),
            payload:{
                verify_member_seasonal:normalize(verify_member_seasonal),
                verify_member_seasonal_error:false
            }
        });
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e?.response));
        const message = parseError?.data;
        yield put({
                type:error(VERIFY_MEMBER_SEASONAL),
                payload:{verify_member_seasonal_error:message,error:message}
            })
    }
}

  export function* acledaPaymentWorker({ payload }) {
    try {
        const acleda = yield call(axios.post,"/acleda-pay",payload);
        yield put({
            type: success(ACLEDA_PAYMENT),
            payload:{
                acledaPayment:normalize(acleda)?.data,
            }
        });
    } catch (e) {
        yield put({
        type: error(ACLEDA_PAYMENT),
        payload: {
            acledaPayment: 'error',
        },
        });
    }
    } 
export function* homeSaga() {
    yield takeLatest(GET_JOB_TYPE, getTypeJobWorker);
    yield takeLatest(GET_JOB_TYPE_SEASONAL, getTypeJobSeasonalWorker);
    yield takeLatest(VERIFY_MEMBER, verifyMemberWorker);
    yield takeLatest(VERIFY_PAY, paymentVerifyWorker);
    yield takeLatest(SUBMIT_FORM_TEMPOTERY, SubmitFormTempoteryWorker);
    yield takeLatest(WING_GENERATE_HASH, wingGenerateHashWorker);
    yield takeLatest(VERIFY_MEMBER_SEASONAL, verifyMemberSeasonalWorker);
    yield takeLatest(SUBMIT_FORM_SEASONAL, SubmitFormSeasonalWorker);
    yield takeLatest(ACLEDA_PAYMENT, acledaPaymentWorker);
}

  