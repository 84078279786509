import { connect } from "react-redux";
import ScreenSignup from "../screen/ScreenSignup"
import {userSigup_f,userLogin_f} from '../modules/user/reducer'
const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = {
  userSigup_f,userLogin_f
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScreenSignup);
