export function findItem(array,key){
    if(array.length>0){
        var a=array.find((e)=>e['value']===key);
        return a?.['title'];
    }return null;
}

export function findMonth(array,key){
    var newMonth=[];
    if(array.length>0){
        array.map((one,o)=> newMonth=[...newMonth,{title:one,id:`${o+1}`}]);
    }
    var a=newMonth.find((e)=>e["id"]===key);
    return a['title']
}
export function findGender(array,key){
    if(array.length>0){
        var a=array.find((e)=>e['value']===key);
        if(a && a['title'])   
        return a['title']
    }
    return null;
}