import { call, put, takeLatest } from "redux-saga/effects";
import { error, success } from "redux-saga-requests";
import {
    GET_NAME,APP_REFRESH,APP_WILL_REFRESH,
    APP_SCHEDULE,
    APP_INFO,
    APP_SCHEDULE_SEASONAL
} from "./reducer";
import axiosDefault from "axios";
import {normalize} from "./../../utils/normalize"
import {store} from '../../store'
import storage from 'redux-persist/lib/storage';

const baseURL = process.env.REACT_APP_API_URL;
export const axios = axiosDefault.create({
  baseURL
});


axios.interceptors.response.use(
    response => {
        return response
    },
    error => {
        try {
        if (
            error.response &&
            error.response.status === 401 
        ) {
            if(window.location.pathname.indexOf("/dashboard")>-1 ||window.location.pathname.indexOf("/login")>-1 ){
                window.location.replace("/login");
            }else{
                window.location.replace("/");
            }
        }      
        else if(
            error.response &&
            error.response.status === 403
        ){
           
        }
        return Promise.reject(error);
        } catch (e) {
        return Promise.reject(e);
        }
    }
);


export function* getNameWorker(payload) {
    try {
        yield put({
            type: success(GET_NAME),
            payload: {
                user:payload.payload
            }
        });
    } catch (e) {
        yield put({
            type: error(GET_NAME)
        });
    }
}

export function* appRefreshWorker() {
    try {
        var cookie = store.getState();
        if(window.location.pathname.indexOf("/dashboard")>-1 ||window.location.pathname.indexOf("/login")>-1 ){
            if(cookie.user.login_admin && cookie.user.login_admin.token){
                yield (axios.defaults.headers.common = {
                    "Authorizationadmin": `Bearer ${cookie.user.login_admin.token}`,
                });
            };
        }
        if(window.location.pathname==="/" || window.location.pathname==="/result" || window.location.pathname==="/form" || window.location.pathname==="/form-swp"){
            if(cookie.user.login && cookie.user.login.token){
                yield (axios.defaults.headers.common = {
                    "Authorization": `Bearer ${cookie.user.login.token}`,
                });
            }
        };
        yield put({
            type: success(APP_REFRESH),
        });
           
    } catch (e) {
        yield put({
            type: error(APP_REFRESH)
        });
    }
    
}

export function* appWillRefreshWorker() {
    try {
        yield put({
            type: success(APP_WILL_REFRESH),
        });
    } catch (e) {
        yield put({
            type: error(APP_WILL_REFRESH)
        });
    }
}

export function* appSchedule() {
    try {
        const schedule_exam = yield call(axios.get,'/schedules/0');
        const data= normalize(schedule_exam);   
        yield put({
            type: success(APP_SCHEDULE),
            payload: {
                schedule_exam: data,
            }
        });
    } catch (e) {
        yield put({
            type: error(APP_SCHEDULE),
            payload: {
                schedule_exam: e?.response?.data,
            }
        });
    }
}

export function* appScheduleSeasonal() {
    try {
        const schedule_exam_seasonal = yield call(axios.get,'/schedules-seasonal/0');
        const data= normalize(schedule_exam_seasonal);   
        yield put({
            type: success(APP_SCHEDULE_SEASONAL),
            payload: {
                schedule_exam_seasonal: data,
            }
        });
    } catch (e) {
        yield put({
            type: error(APP_SCHEDULE),
            payload: {
                schedule_exam_seasonal: e?.response?.data,
            }
        });
    }
}

export function* appInfo() {
    try {
        const appInfo = yield call(axios.get,'/side-info/website/'+new Date().getTime());
        const data= normalize(appInfo);   
        yield put({
            type: success(APP_INFO),
            payload: {
                appInfo: data,
            }
        });
    } catch (e) {
        storage.removeItem('persist:@local10010')
        yield put({
            type: error(APP_INFO)
        });
    }
}


export function* appSaga() {
    yield takeLatest(GET_NAME, getNameWorker);
    yield takeLatest(APP_REFRESH, appRefreshWorker);
    yield takeLatest(APP_WILL_REFRESH, appWillRefreshWorker);
    yield takeLatest(APP_SCHEDULE, appSchedule);
    yield takeLatest(APP_SCHEDULE_SEASONAL, appScheduleSeasonal);
    yield takeLatest(APP_INFO, appInfo);

}