/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { IMAGE } from '../assets/image';
import Header from '../components/header'
import { Col, Row } from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom'
import { Dropdown, Button } from 'react-bootstrap'
import '../styles/ScreenResult.css';
import moment from 'moment';
import {validStream,progressStream,
  pushSuccess
} from '../utils';
import Loading from '../components/loading';

const REACT_APP_IMAGE_PROFILE_URL=process.env.REACT_APP_IMAGE_PROFILE_URL;
export default class ScreenLogin extends React.Component {
  constructor() {
    super();
    this.state = {
      waitingPayment:false,
      profile: null,
      schedule_exam: {
        id: false,
        special: false
      },
      redirect: {
        page: "",
        action: false
      },
    }
  }
  async forceLogOut(){
    alert('ការប្រលងមានការប្រែប្រួល សូមធ្វើការ Login ម្តងទៀត!');
    await localStorage.removeItem('persist:@local10010');
    setTimeout(() => { window.location.replace('/') }, 500);
  }
  componentDidMount() {
    this.setState({
      waitingPayment:true
    })
    const searchParams = new URLSearchParams(this.props.location.search);
    const tranId = searchParams.get('tranId') || '';
    const type = searchParams.get('type') || '';
    const ref = searchParams.get('ref') || '';
    if(typeof tranId==='undefined' || tranId===''){
      // get profile member
      return this.props.profile_f({schedule:0});
    }

    if(typeof tranId==='string' && tranId.length>0){
      validStream(tranId,async(res)=>{
        if(res?.data?.statusCode===200 && res?.data?.data?.value==='pending'){
          // mockup callback ACLEDA
          // await setTimeout(()=>pushSuccess(tranId),10000);
          await progressStream({tranId,type,ref},(res)=>{
            if(res?.data && `${res.data}`.includes('success')){
              return this.props.profile_f({schedule:0});
            }
            return;
          });
          return;
        }
        if(res?.data?.statusCode===400 && res?.data?.data?.value!=='success'){
          return this.forceLogOut();
        }
        return this.props.profile_f({schedule:0});
      })
    }
  }

  async componentWillReceiveProps(NextProps) {
    const { profile } = this.props;
    if (NextProps.profile.profile_ && NextProps.profile.profile_ !== profile.profile_) {
      if (NextProps.profile.profile_.message === "success") {
        this.setState({
          waitingPayment:false,
          profile: NextProps.profile.profile_.data[0]
        });
        return;
      }
      if (NextProps.profile.profile_.message === "empty") {
        return this.forceLogOut();
      }
    }

    // Check user logged
    if(typeof NextProps?.user==='undefined' || NextProps?.user?.login?.message !== 'success'){
      return this.setState({
        redirect: {
          page: "/",
          action: true
        }
      })
    }
  }
  _loopDropmenu(array) {
    var rs = [];
    if (array && array.length > 0) {
      array.map((one, inex) =>
        rs = [...rs, <Dropdown.Item key={inex} href="#/action-1">{one['title']}</Dropdown.Item>]
      );
      return rs;
    }
  }
  _dropdown(array, label, state) {
    return (
      <Dropdown >
        <Dropdown.Toggle className="drop_down_gender">
          ភេទ
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {
            this._loopDropmenu(array)
          }
        </Dropdown.Menu>
      </Dropdown>
    )
  }

  _loopbuttonPayment() {
    var pay = [{ title: 'WING', id: 1 }, { title: 'ABA', id: 2 }];
    var rs = []
    if (pay && pay.length > 0) {
      pay.map((one, i) =>
        rs = [...rs, <Link to="/">
          <Button className="bntPayment" key={i}>
            {
              one['title'] === 'ABA' ?
                <img alt='ABA BANK' src={IMAGE.aba} /> :
                <img alt='WING BANK' src={IMAGE.wing} />
            }
          </Button>
        </Link>]
      );
    }
    return rs;
  }
  _logout() {
    localStorage.clear();
    window.localStorage.clear();
    window.localStorage.removeItem('persist:@local10010');
    window.location.href='/';
  }
  render() {
    const { redirect } = this.state;
    return (
      <div >
        {
          this.state.waitingPayment ? <Loading />:null
        }
        {redirect.action && redirect.page !== "" &&
          <Redirect to={redirect.page} />
        }
        <Row className="fixed_nav" style={{ margin: 0, padding: 0 }}>
          <Header
            logout={
              () => this._logout()
            }
            notication={true}
          />
        </Row>

        <div className="row form_resutl">
          <Col xl={3} lg={3} md={2} sm={1} xs={0}>
          </Col>
          <Col xl={6} lg={6} md={8} sm={10} xs={12} className="text-center">
            {
              this.state.profile && this.state.profile['status'] === 1 ?
                <h1 className="maintitle_result_suceess">
                  អ្នកបានបង់ប្រាក់ជូន <br />
                  (HRD-KOREA)រួចរាល់
                </h1>
                : null
            }

            <div className="cont_result_padding">
              <img crossOrigin="anonymous" alt='profile' src={this.state.profile && this.state.profile['image'] ? `${REACT_APP_IMAGE_PROFILE_URL}/${this.state.profile['folder']}/${this.state.profile['image']}` : IMAGE.user} id="result_id_pf"></img>
              <h1 className="lbl_name">{this.state.profile && String(this.state.profile['name']).toUpperCase()}</h1>
              <p className="lbl_show_">នាមត្រកូល និងនាមខ្លួនជាអក្សរឡាតាំង</p>
              <div className="code_exam">
                <h1 className="code_">{this.state.profile && this.state.profile['codeExam']}</h1>
                <p className="lblExam">លេខកូដប្រឡង</p>
              </div>
              <div className="show_idefication">
                <h1 className="code_">{this.state.profile && this.state.profile['passport']}</h1>
                <p className="lbl_show_">លេខអត្តសញ្ញាណប័ណ្ណ ឬលេខលិខិតឆ្លងដែន</p>
              </div>
              <h1 className="code_Job">ប្រភេទការងារ៖ <span className="mainJob"> {this.state.profile && this.state.profile['jobName']} </span></h1>
              <h1 className="code_Job">ការងារបន្ទាប់បន្សំ៖ <span className="mainJob"> {this.state.profile && this.state.profile['mainJobName']}  </span></h1>

              <div className="show_date">
                <h1 className="code_">កាលបរិច្ឆេទចុះឈ្មោះសុំធ្វើតេស្ត៖</h1>
                <p className="lbl_show_">{this.state.profile && moment(this.state.profile['created']).format('DD-MM-YYYY')}</p>
              </div>


            </div>

          </Col>
          <Col xl={3} lg={3} md={2} sm={1} xs={0}>

          </Col>
        </div>
        {
          this.state.profile && this.state.profile['status'] === 1 ?
            <div className="_Sucess">
              <p>
                សូមរង់ចាំការប្រកាសកាលបរិច្ឆេទប្រឡងតេស្ត នៅលើ
                <a target="_blank" href="http://mtosb.gov.kh/" style={{ color: 'yellow', textDecoration: "underline", marginLeft: 5 }}>
                  គេហទំព័រ,
                </a>
                <a target="_blank" href="https://www.facebook.com/mtosbcambodia" style={{ color: 'yellow', textDecoration: "underline", marginLeft: 5, marginRight: 5 }}>
                  ទំព័រហ្វ៊េសបុកផ្លូវការ,
                </a>
                និង App​​​ របស់ គ.ប.ប.ព.ប
              </p>
            </div> :
            null
        }
      </div>
    );
  }
}
