import { call, put, takeLatest } from "redux-saga/effects";
import { error, success } from "redux-saga-requests";
import {axios} from "../app/index";
import {normalize} from "../../utils/normalize";

import {
    PROFILE,
    PROFILE_SEASONAL
} from './reducer'

export function* profileWorker({payload}){
    try {
        const profile_ = yield call(axios.get,"/result-form/"+payload.schedule+'/'+new Date().getTime());
        yield put({
            type: success(PROFILE),
            payload:{
                profile_:normalize(profile_)
            }
        })
       
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e?.response));
        const message = parseError?.data;
        localStorage.removeItem('persist:@local10010');
        localStorage.removeItem('paymentTran');
        yield put({
            type:error(PROFILE),
            payload:{profile_:message}
        })
    }
}

export function* profileSeasonalWorker({payload}){
    try {
        const profile_seasonal = yield call(axios.get,"/result-form-seasonal/"+payload.schedule+'/'+new Date().getTime());
        yield put({
            type: success(PROFILE_SEASONAL),
            payload:{
                profile_seasonal:normalize(profile_seasonal)
            }
        })
       
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e?.response));
        const message = parseError?.data;
        yield put({
            type:error(PROFILE_SEASONAL),
            payload:{profile_seasonal:message}
        })
    }
}
export function* profileSaga(){
    yield takeLatest(PROFILE,profileWorker);
    yield takeLatest(PROFILE_SEASONAL,profileSeasonalWorker);

}